import { faMapLocationDot, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import copy from "../../template/images/copy.svg";
import qr from "../../template/images/qr.svg";
import { downloadQr } from "../../util/axiosConfig/api/api";
import { ENV } from "./../../util/Env";

const Eventscard = (props) => {
  const { storeData, page } = props;
  const newDate = new Date();
  const navigate = useNavigate();
  const handleRegisterType = (tabName) => {
    navigate(`/Registration/${tabName}`);
  };
  const handleQRCOPYType = (id, type, ename, date, time) => {
    if (type == "qr") {
      const resp = downloadQr(parseInt(id), type, ename, date, time);
      resp.then(
        (success) => {
          console.log("success", success);

          let htmlContent = document.createElement("div");
          htmlContent.innerHTML =
            "QR Downloaded Successfully " +
            `<img src=${success} style="
          width: auto;
          height: 200px;
          display: block;
          text-align: center;
          margin: auto;
      "/>`;
          var a = document.createElement("a"); //Create <a>
          a.href = success; //Image Base64 Goes here
          a.download =
            type + "_" + ename + "_" + date + "_" + time + "_" + ".png"; //File name Here
          a.click(); //Downloaded file
          swal({
            //   title: "QR Downloaded Successfully",
            content: htmlContent,
            html: true,
            icon: "success",
            backdrop: false,
            allowOutsideClick: false,
            showCloseButton: true,
            allowOutsideClick: true,
            showCloseButton: true,
            closeOnClickOutside: false,
            showCancelButton: true,
            focusConfirm: true,
          }).then((value) => {
            if (value === true) {
            }
          });
        },
        (error) => { }
      );
    }
    if (type == "copy") {
      let htmlContent = document.createElement("div");
      htmlContent.innerHTML = `<p style=" text-align: center; margin-top: 45px; ">  Event registration link copied successfully </p>`;
      swal({
        // title: "Registration link Copy Successfully",
        content: htmlContent,
        html: true,
        icon: "success",
        backdrop: false,
        allowOutsideClick: false,
        showCloseButton: true,
        allowOutsideClick: true,
        showCloseButton: true,
        closeOnClickOutside: false,
        showCancelButton: true,
        focusConfirm: true,
      });
      navigator.clipboard.writeText(`${ENV.URL_APP}/Registration/${id}`);
    }
  };

  return (
    <>
      <div
        className={
          page == "reg"
            ? "col-xl-8 col-lg-7 col-md-8 col-sm-12 col-12"
            : "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
        }
      >
        <div
          className={
            page == "reg"
              ? !storeData.offline
                ? "reg_card_online card  usp_card reg_card"
                : "reg_card_offline card  usp_card reg_card"
              : "card  usp_card"
          }
        >
          <div
            className={page == "past" ? "card-body disable-card" : "card-body"}
          >
            {/* {page == "reg"?<div className="regleft_Con"><div className="sub_regleft_Con"><img src={onlineCR}/></div></div>:""} */}
            {page == "reg" ? (
              <></>
            ) : (
              <>
                {!storeData.offline ? (
                  <>
                    <FontAwesomeIcon
                      icon={faVideo}
                      className=" Register_icon_online pe-2"
                    />
                    Online
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faMapLocationDot}
                      className=" Register_icon_offline pe-2"
                    />
                    Offline
                  </>
                )}
              </>
            )}
            <h5 className="usp_title">
              {storeData.eventTopicName ? storeData.eventTopicName : ""}
            </h5>
            <p className="usp_Trainer">
              {" "}
              <span>{storeData.cardTitle ? storeData.cardTitle : "Mutual Fund"}</span>{" "}
            </p>
            <table>
              <tbody>
                <tr className="usp_Trainer">
                  <td>Trainer </td>
                  <td>
                    <span className="mx-2">:</span>
                    <span className="text_span">
                      {storeData.trainerName ? storeData.trainerName : ""}
                    </span>
                  </td>
                </tr>
                <tr className="usp_Trainer">
                  <td>Language </td>
                  <td>
                    <span className="mx-2">:</span>
                    <span className="text_span">
                      {storeData.language ? storeData.language : ""}
                    </span>
                  </td>
                </tr>

                <tr className="usp_Trainer">
                  <td>Date </td>
                  <td>
                    <span className="mx-2">:</span>
                    <span className="text_span">
                      {moment(storeData.startTime).format("DD-MM-YYYY")}
                    </span>
                  </td>
                </tr>
                <tr className="usp_Trainer">
                  <td>Time </td>
                  <td>
                    <span className="mx-2">:</span>
                    <span className="text_span">
                      {moment(storeData.startTime).format("h:mm A")}
                    </span>
                  </td>
                </tr>
                {storeData.offline ? (
                  <tr className="usp_Trainer">
                    <td>Place </td>
                    <td>
                      <span className="mx-2">:</span>
                      <span className="text_span">
                        {storeData.place ? storeData.place : ""}
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {storeData.offline ? (
                  <tr className="usp_Trainer">
                    <td>City </td>
                    <td>
                      <span className="mx-2">:</span>
                      <span className="text_span">
                        {storeData.city ? storeData.city : ""}
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {storeData.offline ? (
                  <tr className="usp_Trainer">
                    <td>Pincode </td>
                    <td>
                      <span className="mx-2">:</span>
                      <span className="text_span">
                        {storeData.pincode ? storeData.pincode : ""}
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {storeData.activeForRegistration == false ? (
                  ""
                ) : (
                  <tr className="usp_Trainer">
                    <td>Slots Available </td>
                    <td>
                      <span className="mx-2">:</span>
                      <span className="text_span">
                        {storeData.maxParticipants - storeData.regCount}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* <p className="usp_Trainer">Trainer : <span>{storeData.trainerName ? storeData.trainerName : ""}</span> </p>
                        {!storeData.offline ? " " : <p className="usp_TYPE"> In <span className={!storeData.offline  ? " Register_icon_online usp_TYPE" : " Register_icon_offline usp_TYPE"}> {storeData.city ? storeData.city : ""} ( {storeData.pincode ? storeData.pincode : ""})</span>   </p>}

                        <p className="usp_TYPE">At  <span> {moment(storeData.startTime).format('DD-MM-YYYY')}</span> <span> @  {moment(storeData.startTime).format('h:mm A')}</span></p> */}
          </div>

          {page == "reg" ? (
            <div className="card-footer ps-3 pe-0 py-0 usp_footer">
              <div className="row justify-content-between align-items-center">
                <div className="col-6 ">
                  {!storeData.offline ? (
                    <div className="Register_icon_online_div">
                      <FontAwesomeIcon
                        icon={faVideo}
                        className=" Register_icon_online px-1"
                      />
                      <span>Online</span>
                    </div>
                  ) : (
                    <div className="Register_icon_offline_div">
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        className=" Register_icon_offline px-1 "
                      />
                      <span>Offline</span>
                    </div>
                  )}{" "}
                </div>
                <div className="col-6  Register_btn_div_reg">
                  <div
                    className={
                      !storeData.offline
                        ? "Register_button_reg Register_button_online"
                        : "Register_button_reg Register_button_offline"
                    }
                  >
                    {!storeData.offline ? (
                      <>
                        <FontAwesomeIcon icon={faVideo} />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faMapLocationDot} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* "
Registration to this event is now closed */}
            </div>
          ) : page == "past" ? (
            <>

            </>
          ) : storeData.status == "Cancelled" ||
            storeData.status == "Completed" ? (
            <div className="card-footer ps-3 pe-0 py-0 usp_footer">
              <div className="row justify-content-between align-items-center">
                <span
                  className={
                    storeData.status == "Completed"
                      ? "stamp is-approved"
                      : storeData.status == "Cancelled"
                        ? "stamp is-nope"
                        : "stamp is-draft"
                  }
                >
                  {storeData.status === "Completed" ? "Completed" : storeData.status}
                </span>
              </div>
            </div>
          ) : storeData.maxParticipants <= storeData.regCount || !storeData.activeForRegistration || storeData.openForEveryone=== false ? (
            <div className="card-footer ps-3 pe-0 py-0 usp_footer">
              <div className="row justify-content-between align-items-center">
                {" "}
                <span className="stamp is-nope">Registration Closed</span>
              </div>
            </div>
          )

            :

            (
              <div className="card-footer ps-3 pe-0 py-0 usp_footer">
                <div className="row justify-content-between align-items-center">
                  <div className="col-6 ">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => handleQRCOPYType(storeData.id, "copy")}
                    >
                      <img src={copy} />{" "}
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={() =>
                        handleQRCOPYType(
                          storeData.id,
                          "qr",
                          storeData.name,
                          moment(storeData.startTime).format("DD-MM-YYYY"),
                          moment(storeData.startTime).format("h:mm A")
                        )
                      }
                    >
                      <img src={qr} />
                    </button>{" "}
                  </div>

                  <div className="col-6  Register_btn_div">
                    <button
                      type="button"
                      disabled={storeData.openForEveryone ? false : 'disabled'}
                      className={
                        !storeData.offline
                          ? "Register_button Register_button_online"
                          : "Register_button Register_button_offline"
                      }
                      onClick={() => handleRegisterType(storeData.id)}
                    >
                      Register
                    </button>{" "}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default Eventscard;
