import moment from "moment/moment";
import React, { useEffect, useState } from "react";
// import "~react-datepicker/dist/react-datepicker.css";
import { Box } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import { components } from "react-select";
import both_icon from "../../template/images/both_icon.svg";
import delf_icon from "../../template/images/delf_icon.svg";
import offline_icon from "../../template/images/offline_icon.svg";
import online_icon from "../../template/images/online_icon.svg";
import InputElement from "../../util/InputElement";
import SelectElement from "../../util/SelectElement";
import { fetchCityList, fetchEventlist, fetchPincodeList, fetchStateList } from "../../util/axiosConfig/api/api";
import Eventslist from "./Eventslist";


const Banner = () => {
  const [selectedDateerrors, setselectedDateerrors] = useState(false);
  const [valuedayjs, setValuedayjs] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  } = useForm();

  const useFromProps = {
    register,
    errors,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  };

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const [selectedTab, setSelectedTab] = useState("Both");
  const [loading, setLoading] = useState(false);
  // todayDetails
  const [todayDetails, setTodayDetails] = useState([]);
  const [todayallDetails, setTodayAllDetails] = useState(false);
  const [todayallbtn, setTodayAllbtn] = useState(false);
  // tomorrowDetails
  const [tomorrowDetails, setTomorrowDetails] = useState([]);
  const [tomorrowallDetails, setTomorrowAllDetails] = useState(false);
  const [tomorrowallbtn, setTomorrowAllbtn] = useState(false);
  // upcomingDetails
  const [upcomingDetails, setUpcomingDetails] = useState([]);
  const [upcomingallDetails, setUpcomingAllDetails] = useState(false);
  const [upcomingallbtn, setUpcomingAllbtn] = useState(false);
  // pastDetails
  const [pastDetails, setPastDetails] = useState([]);
  const [pastallDetails, setPastAllDetails] = useState(false);
  const [pastallbtn, setPastAllbtn] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [uspData, setEventlist] = useState([]);
  const [pinOptions, setPinOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [StateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    handleEventDetails(selectedTab);
  }, [selectedTab, uspData]);
  useEffect(() => {

    handlefetchStateList()
  }, []);
  useEffect(() => {
    fetchgetEventlist("NA", "NA", "NA", "NA", "NA")
  }, []);

  const handleEventType = (tabName) => {
    setSelectedTab(tabName);
    handleEventDetails(tabName);
  };
  const fetchgetEventlist = (state,
    city,
    pincode, date, subject) => {
    const temEventlist = []
    setEventlist([])
    const resposnse = fetchEventlist(state, city, pincode, date, subject);
    resposnse.then(
      (success) => {
        setLoading(false);
        Object.values(success).map((d, i) => {


          temEventlist.push(d);
          setEventlist(temEventlist);
        });
      },
      (error) => {
        setLoading(false);
        // setShowpopup(true);
        // setPopup(true);
        // setErrorObj(error);
      }
    );
  };


  const handleEventDetails = (tabName) => {
    const temstoreData = [];

    if (tabName === "Online") {
      handleEventList(uspData.filter((x) => x.offline === false));
    } else if (tabName === "Offline") {
      handleEventList(uspData.filter((x) => x.offline === true));
    } else if (tabName === "Both") {
      handleEventList(uspData);
    }
  };
  const handleEventList = (storeData) => {
    const Todaydata = [];
    const Tomorrowdata = [];
    const Upcomingdata = [];
    setTomorrowAllDetails(false);
    setTodayAllDetails(false);
    setUpcomingAllDetails(false);



    //     const today = new Date();
    // today.setHours(0, 0, 0, 0); // Set to midnight to compare dates only
    // const now = new Date();
    // const filteredData = storeData.filter(event => {
    //     const eventStartTime = new Date(event.startTime);
    //     // return eventStartTime <= today;
    //     return eventStartTime >= today;
    //     // return eventStartTime == now;
    // });

    // Get the current date and format it to 'YYYY-MM-DD'
    const today = new Date();
    const temmomentUpcoming = new Date().setDate(2);
    const momentUpcoming = new Date().setHours(0, 0, 0, 0);
    const momentTomorrow = moment(new Date()).add("days", 1).format("DD-MM-YYYY");
    const momenttoday = moment(new Date()).format("DD-MM-YYYY");
    const todayString = new Date().setHours(0, 0, 0, 0);

    // //console.log("eventDate",todayString);
    // Filter events to include only those on today's date
    const filteredpastData = storeData.filter(event => {
      const eventDate = new Date(event.startTime).setHours(0, 0, 0, 0);
      return eventDate < todayString;
    });
    const filteredtodaysData = storeData.filter(event => {
      const eventDate = moment(new Date(event.startTime)).format("DD-MM-YYYY");
      return eventDate === momenttoday;

    });
    const filteredTomorrowData = []
    // storeData.filter(event => {
    //   const eventDate = moment(new Date(event.startTime)).format("DD-MM-YYYY");
    //   return eventDate === momentTomorrow;

    // });
    const filteredUpcomingData = storeData.filter(event => {
      const eventDate = new Date(event.startTime).setHours(0, 0, 0, 0);
      //console.log(eventDate  ,  momentUpcoming);
      return eventDate  > momentUpcoming ;

    });
    setTodayDetails(filteredtodaysData)
    setTomorrowDetails(filteredTomorrowData);
    setUpcomingDetails(filteredUpcomingData);
    setPastDetails(filteredpastData);

    filteredtodaysData.map((d, i) => {
      setTodayAllDetails(true);
      //console.log("d.length",i);
      if (i+1 <= 3) {
        setTodayAllbtn(true);
      } else {
        setTodayAllbtn(false);
      }
    })
    filteredTomorrowData.map((d, i) => {
      setTomorrowAllDetails(true);
     if (i+1 <= 3) {
        setTomorrowAllbtn(true);
      } else {
        setTomorrowAllbtn(false);
      }
    })
    filteredUpcomingData.map((d, i) => {
      setUpcomingAllDetails(true);
      if (i+1 <= 3) {
        setUpcomingAllbtn(true);
      } else {
        setUpcomingAllbtn(false);
      }
    })
    filteredpastData.map((d, i) => {
      setPastAllDetails(true);
     if (i+1 <= 3) {
        setPastAllbtn(true);
      } else {
        setPastAllbtn(false);
      }
    })
    //     Object.values(
    //       storeData.filter(
    //         (x) =>
    //           moment(x.startTime).format("DD-MM-YYYY") ==
    //           moment(new Date()).format("DD-MM-YYYY")
    //       )
    //     ).map((d, i) => {

    //       setTodayAllDetails(true);
    // setTomorrowAllDetails(true);
    // setUpcomingAllDetails(true);

    //       if (i <= 3) {
    //         setTodayAllbtn(true);
    //       } else {
    //         setTodayAllbtn(false);
    //       }

    //       Todaydata.push(d);
    //       setTodayDetails(Todaydata);
    //     });

    //     Object.values(
    //       storeData.filter(
    //         (x) =>
    //           moment(x.startTime).format("DD-MM-YYYY") ==
    //           moment(new Date()).add("days", 1).format("DD-MM-YYYY")
    //       )
    //     ).map((temTomorrowdata, i) => {

    //       setTodayAllDetails(true);
    // setTomorrowAllDetails(true);
    // setUpcomingAllDetails(true);
    //       if (i <= 3) {
    //         setTomorrowAllbtn(true);
    //       } else {
    //         setTomorrowAllbtn(false);
    //       }

    //       Tomorrowdata.push(temTomorrowdata);
    //       setTomorrowDetails(Tomorrowdata);
    //     });

    //     Object.values(
    //       storeData).filter(
    //         (x) =>
    //           moment(x.startTime).format("DD-MM-YYYY") ==
    //           moment(new Date()).add("days", 2).format("DD-MM-YYYY")
    //       ).map((d, i) => {

    //         setTodayAllDetails(true);
    // setTomorrowAllDetails(true);
    // setUpcomingAllDetails(true);
    //         if (i <= 3) {
    //           setUpcomingAllbtn(true);
    //         } else {
    //           setUpcomingAllbtn(false);
    //         }

    //         Upcomingdata.push(d);
    //         setUpcomingDetails(Upcomingdata);
    //       });
  };

  const handlefetchStateList = async (ifscCode) => {
    setLoading(true);
    try {
      const res = await fetchStateList();
      if (res) {
        setLoading(false);
        //console.log("res", res);
        let options = res.map((item, i) => {

          let singleData = {
            ...item,
            value: item,
            label: item,

          };

          return singleData
        });
        setStateOptions([...options])
        setCityOptions([])
        setPinOptions([])

      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handlefetchCityList = async (ifscCode) => {
    setLoading(true);
    setCityOptions([])
    try {
      const res = await fetchCityList(ifscCode);
      if (res) {
        setLoading(false);
        //console.log("res", res);
        let options = res.map((item, i) => {

          let singleData = {
            ...item,
            value: item,
            label: item,

          };

          return singleData
        });
        setCityOptions([...options])
        setPinOptions([])
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handlefetchPincodeList = async (ifscCode) => {
    setLoading(true);
    try {
      const res = await fetchPincodeList(ifscCode);
      if (res) {
        setLoading(false);

        //console.log("res", res);
        let options = res.map((item, i) => {

          let singleData = {
            ...item,
            value: item,
            label: item,

          };

          return singleData
        });
        setPinOptions([...options])
      }
    } catch (error) {
      setLoading(false);
    }
  };
  let divClassName = " col-12 "
  let divClassNameSelectElement = " col-12 "
  const startOfQ12022 = moment().subtract(12, "months");
  const endOfQ12022 = moment().add(3, "months");
  const daterval = (data, x, y, type, max, min) => {
    let temdate = false;
    if (data === null) {
      if (data == "Invalid Date" || data === null) {
        temdate = true;
      } else {
        temdate = true;
      }
    } else {
      if (data.$d == "Invalid Date") {
        temdate = true;
      } else {
        if (
          max === "maxDate" &&
          min === "minDate"
          //only minDate & maxDate
        ) {
          if (
            moment(data.$d).diff(x, "months") < 0 ||
            moment(data.$d).diff(y, "months") > 0
          ) {
            temdate = true;
          } else {
            temdate = false;
          }
        } else if (
          min === "minDate" //only minDate
        ) {
          if (
            moment().subtract(12, "months").diff(data.$d) <= 0 ||
            moment().diff(moment(data.$d), "months", true) > 3
          ) {
            temdate = true;
          } else {
            temdate = false;
          }
        } else if (
          max === "maxDate" //only maxDate
        ) {
          if (
            moment().add(3, "months").diff(data.$d) > 0 ||
            moment(data.$d).diff(moment(), "months", true) > 12
          ) {
            temdate = true;
          } else {
            temdate = false;
          }
        }
      }
    }
    switch (type) {
      case "DOB":
        setselectedDateerrors(temdate);
        break;

      default:
        break;
    }
  };

  const onSubmit = async (data) => {
   
    //console.log("async", data);
    let temstate = data.State ? data.State.value : "NA";
    let temcity = data.city ? data.city.value : "NA"
    let tempincode = data.PinCode ? data.PinCode.value : "NA"
    let temdate = data.selectedDate==undefined ?  "NA" : moment(valuedayjs.$d).format("MM-yyyy")
    let temsubject = data.SearchName ? data.SearchName : "NA"
    // async request which may result error
    try {
      fetchgetEventlist(temstate, temcity, tempincode, temdate, temsubject)
    } catch (e) {
      // handle your error
    }
  };
  return (
    <div className="hero_main">
      <div
        className={
          selectedTab == "Online"
            ? "hero_inner hero_inner_online_bg"
            : selectedTab == "Offline"
              ? "hero_inner hero_inner_offline_bg"
              : "hero_inner hero_inner_Both_bg"
        }
      >
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h3 className="hero_text">Investor Awareness Program </h3>
              <p className="hero_description">
                Welcome to the  Investor Awareness Program (IAP), a pioneering initiative aimed at empowering individuals with essential knowledge about mutual funds and financial planning. As the industry body for mutual funds in India,  is committed to promoting investor education and fostering a culture of financial literacy nationwide.

              </p>
              <p className="sub_hero_description">
                <strong> Why Join the  IAP?</strong>
                <ui style={{ listStyle: "none", textAlign:"justify" }}>
                  <li className="mt-2"> <strong>Gain valuable insights:</strong> <span  style={{ listStyle: "none", textAlign:"justify" }}> Our comprehensive Programs cover a wide range of topics, from understanding mutual fund basics to advanced investment strategies, delivered by industry experts.
                    Learn at your own pace: Access informative sessions in multiple languages, providing flexibility and convenience to participants from diverse backgrounds.
                    </span>
                    </li>
                  <li className="mt-2"><strong>Empower yourself financially:</strong><span  style={{ listStyle: "none", textAlign:"justify" }}>  Acquire the knowledge and skills needed to make informed investment decisions, plan for your financial goals, and secure your future. </span></li>
                  <li className="mt-2"><strong>  Interact and engage:</strong> <span  style={{ listStyle: "none", textAlign:"justify" }}> Connect with fellow participants, ask questions, and engage in discussions to deepen your understanding and share experiences.
                    Register now to embark on a journey towards financial empowerment and secure your financial future with confidence. Don't miss this opportunity to expand your financial knowledge and take control of your investments.</span></li>
                </ui>
                {/* <img src={localStorage.getItem("shortLogo")} width={100} height={100} alt="logo"/> */}
                <img src={localStorage.getItem("shortLogo")} className="body_logo" alt="logo"/>
              </p>
              <p className="sub_hero_description">
                Join the IAP today!
              </p>
              
            </div>
          </div>
        </div>
      </div>
      <div className="hero_ftr">
        <div className="container-lg">
          <div
            className={
              selectedTab == "Online"
                ? "hero_ftr_inner hero_ftr_inner_online_bg"
                : selectedTab == "Offline"
                  ? "hero_ftr_inner hero_ftr_inner_offline_bg"
                  : "hero_ftr_inner hero_ftr_inner_Both_bg"
            }
          >
            <div className="hero_question">
              <div className="hero_question_Both_bg"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg usp_sec_main_title">
        <div className="event_type_btn">
          <button
            type="button"
            className={
              selectedTab == "Online"
                ? "Register_button_online"
                : "event_type_tab"
            }
            onClick={() => handleEventType("Online")}
          >
            {" "}
            {selectedTab == "Online" ? (
              <img src={delf_icon} />
            ) : (
              <img src={online_icon} />
            )}{" "}
            Online
          </button>
          <button
            type="button"
            className={
              selectedTab == "Offline"
                ? "Register_button_offline"
                : "event_type_tab"
            }
            onClick={() => handleEventType("Offline")}
          >
            {selectedTab == "Offline" ? (
              <img src={delf_icon} />
            ) : (
              <img src={offline_icon} />
            )}{" "}
            Offline
          </button>
          <button
            type="button"
            className={
              selectedTab == "Both" ? "Register_button_both" : "event_type_tab"
            }
            onClick={() => handleEventType("Both")}
          >
            {" "}
            {selectedTab == "Both" ? (
              <img src={delf_icon} />
            ) : (
              <img src={both_icon} />
            )}{" "}
            Both{" "}
          </button>
          <button className = {!showFilter? "btn event_type_HidFilter":"event_type_showFilter"} onClick = {() => setShowFilter(!showFilter)}><i className="fa fa-filter" aria-hidden="true"></i></button>
        </div>
   
        {/* <img src={offline_bg} className="mt-3" /> */}

        {showFilter ? 
        <div className="row mt-5">
          <div className="col-md-10 col-12">
            <div className="row">
              <div className="col-md-4 col-12">
                <InputElement
                  {...useFromProps}
                  divClassName={divClassName}
                  useForm={useForm}
                  readOnly={false}
                  type="text"
                  labelName="Search by Event Topic Name"
                  sublabelName=""

                  registerName={"SearchName"}
                  name={"SearchName"}
                  mandatory={false}

                  onChange={(event) => { }}
                // errorLabel="Please enter valid name"
                />
              </div>
              <div className="col-md-3 col-12">
                <div className=" col-12 date-input-field">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, value, name, ref },
                      }) => {
                        return (
                          <DesktopDatePicker
                            displayStaticWrapperAs="desktop"
                            clearable
                            views={['month']}
                            inputFormat="MMM-YYYY"
                            // openTo="days"
                            onChange={(newValue) => {
                              daterval(
                                newValue,
                                moment()
                                  .subtract(12, "months")
                                  .calendar(),
                                moment()
                                  .add(3, "months")
                                  .calendar(),
                                "DOB",
                                "maxDate",
                                "minDate"
                              );
                              onChange(newValue);
                              setValuedayjs(newValue);
                              //console.log("newValue",newValue);
                              setValue(
                                name,
                                newValue
                                  ? moment(newValue).format(
                                    "MMM-YYYY"
                                  )
                                  : newValue
                              );
                            }}
                            minDate={startOfQ12022}
                            maxDate={endOfQ12022}
                            value={valuedayjs}
                            label="Helper text example"
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box>
                                <div className="form-floating formgroup col-12">
                                <label htmlFor="" className="p-0">Select Month</label>
              
                                  <input
                                    {...inputProps}
                                    inputRef={ref}
                                    ref={inputRef}
                                    type="text"
                                    name={name}
                                    readOnly={false}
                                    className={
                                      errors.selectedDate ||
                                        selectedDateerrors
                                        ? "form-control  formcontrol_ipa errorsborder "
                                        : "form-control  formcontrol_ipa "
                                    }
                                    id="first-name"


                                  />
                             
                                  {InputProps?.endAdornment}
                                </div>
                              </Box>
                            )}
                          />
                        );
                      }}
                      {...register("selectedDate", {
                        // required: "Please enter valid Date of Birth",
                      })}
                      name="selectedDate"
                    />
                  </LocalizationProvider>
                  {(!errors?.selectedDate?.ref?.value ||
                    selectedDateerrors) && (
                      <small className="textdanger textdangerpadd">
                        {selectedDateerrors
                          ? "Please enter valid Date "
                          : errors?.selectedDate?.message}

                      </small>
                    )}


                </div>
              </div>
              
            </div>
            <div className="row">
            <div className="col-md-3 col-12">
                <SelectElement

                  {...useFromProps}
                  clearable
                  divClassName={divClassNameSelectElement}
                  readOnly={false}
                  registerName={"State"}
                  mandatory={false}
                  labelName={"State"}
                  options={StateOptions}
                  onSelect={(value) => {
                    handlefetchCityList(value.value)
                    setValue('PinCode', "")
                    setValue('city', "")
                  }}

                /></div>

              <div className="col-md-3 col-12">
                <SelectElement

                  {...useFromProps}
                  // ref={"city"}

                  clearable
                  divClassName={divClassNameSelectElement}
                  readOnly={false}
                  registerName={"city"}
                  mandatory={false}
                  labelName={"City"}
                  options={cityOptions}
                  onSelect={(value) => {
                    handlefetchPincodeList(value.value)
                    setValue('PinCode', "")
                  }}

                /></div>
              <div className="col-md-2 col-12">
                <SelectElement

                  {...useFromProps}
                  clearable
                  divClassName={divClassNameSelectElement}
                  readOnly={false}
                  registerName={"PinCode"}
                  mandatory={false}
                  labelName={"Pincode"}
                  options={pinOptions}
                  onSelect={(value) => {


                  }}

                />
              </div>
              <div className="col-md-3 col-6">
                <button className="Reg_Button" onClick={handleSubmit(onSubmit)}>Apply Filter</button>
              </div>

            </div>
          </div>
        </div>
        : null }






      </div>

      <Eventslist
        EventType={selectedTab}
        upcomingDetails={upcomingDetails}
        upcomingallDetails={upcomingallDetails}
        upcomingallbtn={upcomingallbtn}
        pastDetails={pastDetails}
        pastallDetails={pastallDetails}
        pastallbtn={pastallbtn}
        todayDetails={todayDetails}
        todayallDetails={todayallDetails}
        todayallbtn={todayallbtn}
        tomorrowDetails={tomorrowDetails}
        tomorrowallDetails={tomorrowallDetails}
        tomorrowallbtn={tomorrowallbtn}

      ></Eventslist>





    </div>
  );
};

export default Banner;
